<template>
  <b-modal
    ref="modal"

    :title="(options && options.title ? options.title : 'Time & date')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <div class="container">
        <!-- begin::Message -->
        <span v-html="(options && options.message ? options.message : `Do you want to overwrite the time & date?`)" />
        <!-- end::Message -->
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoading" @click.prevent="onSubmit(false)">No</button>
      <button type="button" class="btn btn-info" :disabled="isLoading" @click.prevent="onSubmit(true)">Yes</button>
    </template>
  </b-modal>
</template>

<script>
import { Mixins } from '@vedicium/metronic-vue';

export default {
  mixins: [Mixins.Modal],
  name: 'datetimeOptionModal',
  data () {
    return {
      event: 'equipment:settings:datetime-option',

      options: null,
      isLoading: false,
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on(`modals:${this.event}:open`, this.open);
    this.$eventhub.on(`modals:${this.event}:close`, this.close);
  },
  beforeDestroy () {
    // Listeners
    this.$eventhub.off(`modals:${this.event}:open`, this.open);
    this.$eventhub.off(`modals:${this.event}:close`, this.close);
  },

  methods: {
    async onSubmit (value = null) {
      this.$set(this, 'isLoading', true);
      try {
        // Only execute onSubmit if options.onSubmit is set
        // We don't try to remove the resource by default
        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit((value === true));
        }
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open (options = {}) {
      this.$set(this, 'options', options || {});

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {
      // Nothing do to here
    },
  },
};
</script>
