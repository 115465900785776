<template>
  <b-modal
    ref="modal"

    :title="(options && options.title ? options.title : 'Instructions')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <div class="container">
        <div class="row d-none d-md-flex border-bottom pb-3">
          <div class="col-auto mx-auto">
            <img :src="require('@/assets/images/sunbeds/assets/P10241-enter-settings.svg')" class="img-fluid" alt="P10241 - Enter settings">
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <span>Enter the parameter-menu on your sunbed within ten minutes in order to confirm external parameter changes.
In order to do so, hold the upper three buttons of the sunbed’s control panel until the parameters-menu appears. Then enter your PIN and click the start button.</span>
          </div>
        </div>
        <!-- end::Message -->
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoading" @click.prevent="close()">Close</button>
    </template>
  </b-modal>
</template>

<script>
import { Mixins } from '@vedicium/metronic-vue';

export default {
  mixins: [Mixins.Modal],
  name: 'parameterInstructionsModal',
  data () {
    return {
      event: 'equipment:settings:instructions',

      options: null,
      isLoading: false,
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on(`modals:${this.event}:open`, this.open);
    this.$eventhub.on(`modals:${this.event}:close`, this.close);
  },
  beforeDestroy () {
    // Listeners
    this.$eventhub.off(`modals:${this.event}:open`, this.open);
    this.$eventhub.off(`modals:${this.event}:close`, this.close);
  },

  methods: {
    open (options = {}) {
      this.$set(this, 'options', options || {});

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {
      // Nothing do to here
    },
  },
};
</script>
