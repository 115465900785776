<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Requests history')"

    size="xl"
    centered

    :body-class="['pt-3', (isLoading && log_options.length === 0 ? 'pb-3' : 'pb-0')]"
  >
    <template v-slot:default>
      <div class="row">
        <div class="col pb-3">
          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :filters="datatable.filters"
            :search-fields="datatable.searchFields"
            :sort-order="datatable.sortOrder"
            :on-pagination-data="onPaginationData"
            :load-on-start="false"

            :transform="transform"
          >
            <!-- begin::Actions -->
            <div slot="actions" slot-scope="props">
              <b-dropdown
                ref="dropdown:actions"
                variant="clean btn-sm btn-icon"
                class="dropdown-inline mr-2"
                right
                lazy
                no-caret
                boundary="window"
                menu-class="dropdown-menu-md"
              >
                <template v-slot:button-content>
                  <span class="svg-icon">
                    <font-awesome-icon :icon="['fas', 'cog']" />
                  </span>
                </template>

                <template v-slot:default>
                  <ul class="navi flex-column navi-hover py-2">
                    <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                      Choose an action:
                    </li>

                    <li class="navi-separator my-0" />

                    <template v-if="props.rowData.json_data && props.rowData.json_data.paraId">
                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="showCorrespondingSettings(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'cogs']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Corresponding settings</span>
                        </a>
                      </li>

                      <li class="navi-separator my-0" />
                    </template>

                    <template>
                      <li class="navi-item">
                        <a class="navi-link cursor-pointer" @click.prevent="downloadMutations(props.rowData)">
                          <span class="navi-icon">
                            <i>
                              <font-awesome-icon :icon="['fas', 'file-csv']" class="w-15px h-15px" />
                            </i>
                          </span>
                          <span class="navi-text">Download mutations</span>
                        </a>
                      </li>
                    </template>
                  </ul>
                </template>
              </b-dropdown>
            </div>
            <!-- end::Actions -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" @click.prevent="close">Close</button>
    </template>
  </b-modal>
</template>

<script>
import { Mixins, DatatableMixin } from '@vedicium/metronic-vue';
import moment from 'moment-timezone';

import Equipment from '@/libs/classes/equipment';
import EquipmentSettingsRequest from '@/libs/classes/equipment_settings_requests';

export default {
  mixins: [Mixins.Modal, DatatableMixin],
  name: 'settingsRequestsModal',
  data () {
    return {
      event: 'equipment:settings:requests',
      options: {},
      equipment: null,

      isLoading: false,

      datatable: {
        url: `${Equipment.uri}`,

        fields: [{
          name: '_meta.created',
          title: 'Date',
          sortField: '_meta.created',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
          formatter: (value) => moment.utc(value).tz(this.equipment.location.timezone || 'UTC').format('LLL'),
        }, {
          name: 'resource.name',
          title: 'User',
          sortField: 'resource.name',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'company.name',
          title: 'Company',
          sortField: 'company.name',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }],

        sortOrder: [{
          field: '_meta.created',
          direction: 'desc',
        }],
      },
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on(`modals:${this.event}:open`, this.open);
    this.$eventhub.on(`modals:${this.event}:close`, this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off(`modals:${this.event}:open`, this.open);
    this.$eventhub.off(`modals:${this.event}:close`, this.close);
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new EquipmentSettingsRequest().merge(row));
      }
      return response;
    },

    async downloadMutations (request = null) {
      if (!request || request instanceof EquipmentSettingsRequest === false) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        await request.download({ filename: `${this.equipment.serial}.settings-mutations.csv` });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },

    async open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      this.$set(this, 'equipment', options.equipment);
      if (this.equipment instanceof Equipment === false) {
        throw new Error('Equipment missing');
      }

      // Open modal first, load after that
      this.$refs.modal.show();

      this.$set(this.datatable, 'url', `${Equipment.uri}/${this.equipment._meta.guid}/settings/requests`);
      await this.$nextTick();
      this.$refs.datatable.reload();
    },
    close () {
      this.$refs.modal.hide();
    },
  },
};
</script>
