<template>
  <b-dropdown
    ref="log-actions"
    :variant="($props.variant || 'outline-secondary')"
    class="dropdown-inline"
    no-caret
    menu-class="dropdown-menu-md"
    :boundary="($props.boundary || 'window')"
    :disabled="disabled"
  >
    <template v-slot:button-content>
      <i class="svg-icon">
        <font-awesome-icon :icon="['fas', 'cog']" />
      </i>

      <span>Actions</span>
    </template>

    <template v-slot:default>
      <ul class="navi flex-column navi-hover py-2">
        <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
          Choose an action:
        </li>

        <li class="navi-separator my-0" />

        <!-- begin::Parameters -->
        <li
          class="navi-item"
          @click.prevent="toggleSpecialMode(!settings.special_mode)"
        >
          <a class="navi-link cursor-pointer">
            <span class="navi-icon">
              <i :class="{ 'text-primary': settings.special_mode === true }">
                <font-awesome-icon
                  :icon="['fas', (settings.special_mode === true ? 'eye' : 'eye-slash')]"
                  class="w-15px h-15px"
                />
              </i>
            </span>
            <span class="navi-text">Special mode</span>
          </a>
        </li>
        <!-- end::Parameters -->

        <li class="navi-separator my-0" />

        <!-- begin::Import / export -->
        <li v-if="type === 'modify' && $auth.hasPermission('equipment.controller.parameters.update.advanced')" class="navi-item">
          <a class="navi-link cursor-pointer" @click.prevent="$emit('action', 'import', { log, options: {} })">
            <span class="navi-icon">
              <i>
                <font-awesome-icon :icon="['fas', 'file-import']" class="w-15px h-15px" />
              </i>
            </span>
            <span class="navi-text">Import settings</span>
          </a>
        </li>

        <li class="navi-item">
          <a class="navi-link cursor-pointer" @click.prevent="$emit('action', 'export', { log, options: {} })">
            <span class="navi-icon">
              <i>
                <font-awesome-icon :icon="['fas', 'file-export']" class="w-15px h-15px" />
              </i>
            </span>
            <span class="navi-text">Export settings</span>
          </a>
        </li>
        <!-- end::Import / export -->

        <li class="navi-separator my-0" />

        <!-- begin::Download -->
        <li class="navi-item">
          <a class="navi-link cursor-pointer" @click.prevent="$emit('action', 'download', { log, options: { query: { format: 'csv' } } })">
            <span class="navi-icon">
              <i>
                <font-awesome-icon :icon="['fas', 'file-csv']" class="w-15px h-15px" />
              </i>
            </span>
            <span class="navi-text">Download CSV</span>
          </a>
        </li>

        <li class="navi-item">
          <a class="navi-link cursor-pointer" @click.prevent="$emit('action', 'download', { log, options: { query: { format: 'json' } } })">
            <span class="navi-icon">
              <i>
                <font-awesome-icon :icon="['fas', 'file-code']" class="w-15px h-15px" />
              </i>
            </span>
            <span class="navi-text">Download JSON</span>
          </a>
        </li>
        <!-- end::Download -->
      </ul>
    </template>
  </b-dropdown>
</template>

<script>
import LuxuraLog from '@/libs/classes/luxura_log';

export default {
  name: 'actionsDropdown',
  props: {
    variant: {
      type: String,
      default: null,
    },
    boundary: {
      type: String,
      default: null,
    },

    log: {
      type: LuxuraLog,
      default: null,
    },

    type: {
      type: String,
      default: 'history',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      settings: {
        special_mode: false,
      },
    };
  },

  mounted () {

  },

  methods: {
    close () {
      this.$refs['log-actions'].hide();
    },

    async toggleSpecialMode (enabled = null, options = {}) {
      if (typeof enabled !== 'boolean') {
        return;
      }

      this.$set(this.settings, 'special_mode', enabled);
      if (options.close_dropdown !== false) {
        this.close();
      }

      this.$emit('action', 'settings', this.settings);
    },
  },
};
</script>
