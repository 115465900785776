<template>
  <b-modal
    ref="modal"

    :title="(options && options.title ? options.title : 'Effect Details')"

    size="lg"
    centered
    @hidden="close"
  >
    <template v-slot:default>
      <div class="container">
        <div class="row pb-3 justify-content-center">
          <div class="col-12 col-lg-6 order-0">
            <div class="input-group">
              <div class="input-group-prepend">
                <button
                  type="button"
                  class="btn btn-light"
                  :disabled="!effects || effects.length === 0"

                  @click.prevent="selectPreviousEffect()"
                >
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </button>
              </div>
              <select
                class="form-control"

                :value="(effectValue)"

                @change="onEffectChange(Number($event.target.value))"
              >
                <option
                  v-for="option in effects"
                  :key="`value:${option.value}`"
                  :value="option.value"
                  v-html="option.text || option.value"
                />
            </select>
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-light"
                  :disabled="!effects || effects.length === 0"

                  @click.prevent="selectNextEffect()"
                >
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-flex flex-column mt-5">
            <div class="col">
              <div>
                <span><small>Preview</small></span>
              </div>
            </div>
            <div class="col">
              <div class="symbol symbol-70">
                <div
                  class="symbol-label shadow-lg"
                  :style="{
                    backgroundColor: currentColorPreview,
                    transition: isInTransition === true ? 'background-color cubic-bezier(1, 1, 1, 1)' : undefined,
                    transitionDuration: isInTransition === true ? `${fadeTimeColor}s` : undefined,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <small>Colors</small>
          </div>
          <div class="col-12">
            <div class="d-flex flex-wrap">
              <div v-for="(color, key) in effectColors" :key="`colorValue:${key}`">
                <div class="mr-2 mb-2">
                  <div class="symbol symbol-25">
                    <div class="symbol-label" :style="{ backgroundColor: color, border: `1px ${color === '#FFF' ? 'solid' : ''} #262626`}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-6">
            <small>Time per color</small>
            <p><strong>{{ `${timePerColor} ${$tc('plural.seconds', timePerColor)}` }}</strong></p>
          </div>
          <div class="col-6">
            <small>Fade time between colors</small>
            <p><strong>{{ `${fadeTimeColor} ${$tc('plural.seconds', fadeTimeColor)}` }}</strong></p>
          </div>
        </div>
        <!-- end::Message -->
      </div>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoading" @click.prevent="close()">Cancel</button>
      <button type="button" class="btn btn-info" :disabled="isLoading" @click.prevent="submit()">Submit</button>
    </template>
  </b-modal>
</template>

<script>
import { Mixins } from '@vedicium/metronic-vue';
import LuxuraLogParameter, { Parameter } from '@/libs/classes/luxura_log.parameter';

export default {
  mixins: [Mixins.Modal],
  name: 'effectOptionModal',
  props: {
    log: {
      type: LuxuraLogParameter,
      default: null,
    },
    parameter: {
      type: Parameter,
      default: null,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
  },

  data () {
    return {
      event: 'equipment:settings:effect-option',

      options: {},
      isLoading: false,

      effects: [],
      effectValue: null,
      effectSpecs: [],
      effectColor: [],

      timePerColor: null,
      fadeTimeColor: null,
      effectColors: [],
      currentColorPreview: null,

      isInTransition: false,

      animationTimer: null,
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on(`modals:${this.event}:open`, this.open);
    this.$eventhub.on(`modals:${this.event}:close`, this.close);
  },
  beforeDestroy () {
    // Listeners
    this.$eventhub.off(`modals:${this.event}:open`, this.open);
    this.$eventhub.off(`modals:${this.event}:close`, this.close);
  },

  methods: {
    open (options = {}) {
      this.$set(this, 'options', options || {});
      this.$set(this, 'effects', JSON.parse(JSON.stringify(options.effects)));
      this.$set(this, 'effectValue', JSON.parse(JSON.stringify(options.effectValue)));
      this.$set(this, 'effectSpecs', JSON.parse(JSON.stringify(options.effectConfig)));
      this.$set(this, 'effectColor', JSON.parse(JSON.stringify(options.effectColor)));
      this.onEffectChange(this.effectValue);

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();

      this.$set(this, 'isInTransition', false);
      clearInterval(this.animationTimer);
    },

    onShown () {
      // Nothing do to here
    },
    previewAnimation (timeColor, fadeColor) {
      // Stop transition
      this.$set(this, 'isInTransition', false);
      clearInterval(this.animationTimer);

      // Don't start a new transition when
      if (timeColor <= 0 || timeColor === null) {
        return;
      }

      // Start transition again
      this.$nextTick(() => this.$set(this, 'isInTransition', true));

      // Set current color to first index of effect colors
      [this.currentColorPreview] = this.effectColors;

      // Determine interval
      const timeColorMS = (timeColor + fadeColor) * 1000;
      let currentIndex = 1;
      this.$set(this, 'animationTimer', setInterval(() => {
        this.currentColorPreview = this.effectColors[currentIndex];
        currentIndex += 1;
        if (currentIndex === undefined || currentIndex >= this.effectColors.length) {
          currentIndex = 0;
        }
      }, timeColorMS));
    },

    checkColorsLoop (value) {
      if (!Array.isArray(this.effectSpecs[value].colors)) {
        return;
      }

      this.effectColors = this.effectSpecs[value].colors.map((row) => this.effectColor[row].color, []);
    },

    selectNextEffect () {
      if (this.effectValue === (this.effects.length - 1)) {
        return;
      }

      this.effectValue = this.effects[this.effectValue + 1].value;
      this.onEffectChange(this.effectValue);
    },
    selectPreviousEffect () {
      if (this.effectValue === 0) {
        return;
      }

      this.effectValue = this.effects[this.effectValue - 1].value;
      this.onEffectChange(this.effectValue);
    },

    onEffectChange (value) {
      this.effectValue = value;
      this.timePerColor = this.effectSpecs[value].timePerColor || 0;
      this.fadeTimeColor = this.effectSpecs[value].fadeTime || 0;

      this.checkColorsLoop(value);
      this.previewAnimation(this.timePerColor, this.fadeTimeColor);
    },

    submit () {
      this.close();

      if (typeof this.options.onSubmit === 'function') {
        this.options.onSubmit(this.effectValue);
      }
    },
  },
};
</script>
