<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Sunbed settings history')"

    size="xl"
    centered

    :body-class="['pt-3', (isLoading && log_options.length === 0 ? 'pb-3' : 'pb-0')]"
  >
    <template v-slot:default>
      <!-- begin::Loading -->
      <template v-if="isLoading">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Loading -->

      <!-- begin::Row - dropdown -->
      <div :class="['row', { 'border-bottom pb-3': log_options && log_options.length }]">
        <!-- begin::Actions -->
        <div class="col-12 mt-3 order-1 col-lg-3 mt-lg-0 order-lg-0">
          <actions-dropdown
            ref="actionsDropdown"

            :log="log"

            @action="onAction"
          />
        </div>
        <!-- end::Actions -->

        <!-- begin::Log selector -->
        <div class="col-12 col-lg-6 order-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <button
                type="button"
                class="btn btn-light"
                :disabled="!log_options || log_options.length === 0 || (log && log._meta.guid === log_options[log_options.length - 1]._meta.guid)"

                @click.prevent="selectPreviousLog()"
              >
                <font-awesome-icon :icon="['fas', 'chevron-left']" />
              </button>
            </div>
            <select
              class="form-control border-left border-right"
              @change.prevent="onLogSelect($event.target.value)"
            >
              <option v-if="!log_options || log_options.length === 0" disabled selected>No logs found</option>

              <template v-else>
                <option v-if="isErrorActive" :selected="isErrorActive" disabled>Log not found</option>

                <option
                  v-for="log in log_options"
                  :key="`equipment.${equipment._meta.guid}.logs.boot.${log._meta.guid}`"
                  :value="log._meta.guid"
                  :selected="$data.log && log._meta.guid === $data.log._meta.guid"
                >
                  {{ $moment.utc(log.log_date).tz(equipment.location.timezone || 'UTC').format('HH:mm [on] DD MMMM YYYY') }} (#{{ log.log_id }} - {{ log.controller_serial }})
                </option>
              </template>
            </select>
            <div class="input-group-append">
              <button
                type="button"
                class="btn btn-light"
                :disabled="!log_options || log_options.length === 0 || (log && log._meta.guid === log_options[0]._meta.guid)"

                @click.prevent="selectNextLog()"
              >
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
              </button>
            </div>
          </div>
        </div>
        <!-- end::Log selector -->
      </div>
      <!-- end::Row - dropdown -->

      <!-- begin::Error -->
      <div v-if="isErrorActive" class="row mt-3 px-5">
        <error-translation-alert
          ref="errorAlert"
          :error="activeError"
          class="col"
        />
      </div>
      <!-- end::Error -->

      <!-- begin::Content -->
      <div
        v-if="log"
        class="row"
      >
        <div class="col">
          <div class="accordion accordion-light">
            <div
              v-for="menu in logMenus"
              :key="`equipment.${equipment._meta.guid}.parameterlog.history.${log._meta.guid}.menu.${menu.menu}`"
              :parameter-menu="menu.menu"
              class="card"
            >
              <div class="card-header">
                <div class="card-title" v-b-toggle="`parameter.history.menu.${menu.menu}`">
                  <!-- Use v-for, so we can scope the getNotificationIcon -->
                  <i
                    v-for="(menuIcon, idx) in [getMenuIcon(menu.menu)]"
                    :key="`equipment.${equipment._meta.guid}.parameterlog.history.${log._meta.guid}.menu.${menu.menu}.icon.${idx}`"
                    class="svg-icon w-25px text-center"
                  >
                    <font-awesome-icon :icon="menuIcon && menuIcon.icon || ['fas', 'question']" />
                  </i>

                  {{ $tu(`luxura_logs.parameter.modeltype_${log.model_type}.version_${log.version_id}.menu_${menu.menu}.title`) || `Menu ${menu.menu}` }}
                </div>
              </div>
              <b-collapse :id="`parameter.history.menu.${menu.menu}`" accordion="parameter-accordion">
                <div v-if="menu.parameters && menu.parameters.length > 0" class="card-body px-3">
                  <parameter-row
                    v-for="(parameter, idx) in menu.parameters"
                    :key="`equipment.${equipment._meta.guid}.parameterlog.history.${log._meta.guid}.menu.${menu.menu}.submenu.${parameter.submenu}`"
                    :ref="`equipment.${equipment._meta.guid}.parameterlog.history.${log._meta.guid}.menu.${menu.menu}.submenu.${parameter.submenu}`"

                    :class="['row mt-3', { 'pb-3 border-bottom': idx !== (menu.parameters.length - 1) }]"

                    :equipment="equipment"
                    :log="log"
                    :parameter="parameter"
                  />
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <!-- end::Content -->
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" @click.prevent="close">Close</button>
    </template>
  </b-modal>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

import Equipment from '@/libs/classes/equipment';
import LuxuraLog from '@/libs/classes/luxura_log';
import LuxuraLogParameter, { ParameterTypes } from '@/libs/classes/luxura_log.parameter';

import actionsDropdown from '../components/actions.dropdown.vue';
import parameterRow from '../components/parameter.row.vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue'; // eslint-disable-line

export default {
  mixins: [Mixins.Modal, errorComponentMixin, equipmentMixin],
  name: 'settingsHistoryModal',
  components: {
    actionsDropdown,
    parameterRow,
    errorTranslationAlert,
  },
  data () {
    return {
      event: 'equipment:settings:history',
      options: {},

      isLoading: false,

      log_options: [],
      log: null,

      settings: {
        special_mode: false,
      },
    };
  },
  computed: {
    logMenus () {
      const menus = this.log && this.log.getMenus();
      return menus && menus
        // Filter menu's first
        .filter((row) => row.menu !== 0)

        // Then, map the parameters of that menu
        .map((row) => ({
          ...row,
          parameters: row.parameters.filter((parameter) => {
            // Filter out types
            if ([ParameterTypes.MENU_NUMERIC_ULONG, ParameterTypes.MENU_TIME, ParameterTypes.MENU_DATE].includes(Number(parameter.type))) {
              return false;
            }

            // Filter out unvisible parameters (special mode has to be turned on)
            if (this.settings.special_mode !== true && parameter.visible === false) {
              return false;
            }

            // Filter out 'hour reset'
            if (this.log.model_type === 'LXP16') {
              // Hours menu & submenu's
              if (parameter.menu === 2 && [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24].includes(parameter.submenu)) {
                return false;
              }
            }

            if (this.log.model_type === 'X5X7II') {
              // Hours menu & submenu's
              if (parameter.menu === 2 && [2, 4, 8, 11, 13].includes(parameter.submenu)) {
                return false;
              }
            }

            if (this.log.model_type === 'V6V8') {
              // Hours menu & submenu's
              if (parameter.menu === 2 && [2].includes(parameter.submenu)) {
                return false;
              }
            }

            return true;
          }),
        }));
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on(`modals:${this.event}:open`, this.open);
    this.$eventhub.on(`modals:${this.event}:close`, this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off(`modals:${this.event}:open`, this.open);
    this.$eventhub.off(`modals:${this.event}:close`, this.close);
  },

  methods: {
    onAction (action = null, payload = {}) {
      switch (action) {
        case 'download': {
          this.onDownload(payload.log, payload.options);
          break;
        }

        case 'settings': {
          this.$set(this, 'settings', payload);
          break;
        }

        case 'export': {
          this.$set(this, 'isLoading', true);
          try {
            const parametersFile = LuxuraLogParameter.convertParametersToFile([].concat(this.log.parameters), {
              exclude_datetime: true,
              include_invisible: this.settings.special_mode,
            });

            // Take advantage of the HTTP.downloadFile method
            this.$http.downloadFile(`${this.equipment.serial}.${this.log.controller_serial}.parameter.${this.log.log_id}.txt`, parametersFile, 'text/plain');
          } catch (e) {
            this.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          } finally {
            this.$set(this, 'isLoading', false);
            this.$refs.actionsDropdown.close();
          }
          break;
        }

        default: {
          console.warn(`Action '${action}' unknown`);
          this.$refs.actionsDropdown.close();
        }
      }
    },

    async onDownload (log = null, options = {}) {
      if (!log || (log instanceof LuxuraLog) === false) {
        return;
      }

      this.$set(this, 'isLoading', true);
      try {
        this.$refs.actionsDropdown.close();
        await log.download(options);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },

    getMenuIcon (menu = null) {
      return this.log && this.log.getMenuIcon(menu);
    },
    filterParameters (parameters = []) {
      return (parameters || []);
    },

    selectNextLog () {
      const logIdx = (this.log_options || []).findIndex((log) => log._meta.guid === this.log._meta.guid);
      if (logIdx === -1 || logIdx === 0) {
        return;
      }
      this.onLogSelect(this.log_options[logIdx - 1]._meta.guid);
    },
    selectPreviousLog () {
      const logIdx = (this.log_options || []).findIndex((log) => log._meta.guid === this.log._meta.guid);
      if (logIdx === -1 || logIdx === (this.log_options.length - 1)) {
        return;
      }
      this.onLogSelect(this.log_options[logIdx + 1]._meta.guid);
    },

    async getLogOptions (options = {}) {
      // TODO: Use endpoint which gathers all options without json_data
      return (await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs`, {
        ...(options || {}),
        query: {
          sort: 'log_date|desc',
          filter: {
            type: 'parameter',
          },
        },
      }) || []).map((log) => new LuxuraLogParameter().merge(log));
    },
    async onLogSelect (guid = null) {
      this.$errors.clear({ component: this });
      this.$set(this, 'isLoading', true);
      try {
        await this.selectLog(guid);
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this });
        return null;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.log;
    },
    async selectLog (guid = null) {
      if (!guid) {
        this.$set(this, 'log', null);
      }

      const log = new LuxuraLogParameter().merge(await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs/${guid}`));
      this.$set(this, 'log', log);

      // Set query log
      if (this.$route.query.log !== log._meta.guid) {
        this.$router.replace({
          ...this.$route,
          query: {
            ...(this.$route.query || {}),
            log: log._meta.guid,
          },
        });
      }

      return this.log;
    },

    async open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      this.$set(this, 'log', null);
      this.$set(this, 'log_options', []);

      // Open modal first, load after that
      this.$refs.modal.show();

      this.$set(this, 'isLoading', true);
      try {
        this.$set(this, 'log_options', await this.getLogOptions());
        if (this.log_options.length) {
          await this.selectLog(this.options.log || this.log_options[0]._meta.guid);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this, ui_element: false });
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
    close () {
      this.$refs.modal.hide();
    },
  },
};
</script>
