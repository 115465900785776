<template>
  <div class="container position-relative">
    <!-- begin::Loading -->
    <template v-if="isMounted === false || isLoading">
      <div class="loading-container loading-overlap">
        <div class="loading-backdrop mt-n2" />
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </template>
    <!-- end::Loading -->

    <!-- begin::Row - dropdown -->
    <div :class="['row', 'border-bottom pb-3']">
      <!-- begin::Actions -->
      <div class="col-12 mt-3 order-1 col-lg-auto mt-lg-0 pr-lg-1 order-lg-0">
        <actions-dropdown
          ref="actionsDropdown"
          variant="outline-secondary btn-block"
          class="dropdown-inline w-100 w-lg-auto"

          :type="(canUpdateSettings() === true ? 'modify' : 'history')"
          :log="log"

          @action="onAction"
        />
      </div>
      <!-- end::Actions -->

      <!-- begin::History -->
      <div class="col-12 mt-3 order-1 col-lg-auto mt-lg-0 pl-lg-1 order-lg-0">
        <div
          class="btn btn-outline-secondary btn-block w-100 w-lg-auto"
          @click.prevent="onHistoryButtonClick"
        >
          <i class="svg-icon">
            <font-awesome-icon :icon="['fas', 'history']" />
          </i>

          <span>History</span>
        </div>
      </div>
      <!-- end::History -->

      <!-- <div class="col-12 mt-3 order-0 col-lg-auto mt-lg-0 m-lg-auto order-lg-0">
        <span class="text-muted">
          Updated {{ $moment.utc(log.log_date).fromNow() }}
        </span>
      </div> -->

      <div v-if="isMounted" class="col-12 mt-3 order-1 col-lg-auto mt-lg-0 ml-lg-auto order-lg-0">
        <!-- begin::Requests -->
        <div
          v-if="$auth.hasPermission('equipment_settings_requests.get')"
          class="btn btn-outline-secondary w-100 w-lg-auto mb-3 mb-lg-0"
          @click.prevent="onRequestsButtonClick"
        >
          <i class="svg-icon">
            <font-awesome-icon :icon="['fas', 'list']" />
          </i>

          <span>Requests</span>
        </div>
        <!-- end::Requests -->

        <!-- begin::Submit -->
        <button
          v-if="canUpdateSettings() === true"

          class="btn btn-success w-100 w-lg-auto ml-lg-3"
          :disabled="isLoading || (mutations && mutations.length === 0)"
          @click.prevent="onSubmit"
        >
          <span>Submit</span>
        </button>
        <!-- end::Submit -->
      </div>
    </div>
    <!-- end::Row - dropdown -->

    <!-- begin::Error -->
    <div v-if="isErrorActive" class="row mt-3 px-5">
      <error-translation-alert
        ref="errorAlert"
        :error="activeError"
        class="col"
      />
    </div>
    <!-- end::Error -->

    <!-- begin::Content -->
    <div
      v-if="log"
      class="row"
    >
      <div class="col">
        <div class="accordion accordion-light">
          <div
            v-for="menu in logMenus"
            :key="`equipment.${equipment._meta.guid}.parameterlog.${log._meta.guid}.menu.${menu.menu}`"
            :parameter-menu="menu.menu"
            class="card"
          >
            <div class="card-header">
              <div class="card-title" v-b-toggle="`parameter.menu.${menu.menu}`">
                <!-- Use v-for, so we can scope the getNotificationIcon -->
                <i
                  v-for="(menuIcon, idx) in [getMenuIcon(menu.menu)]"
                  :key="`equipment.${equipment._meta.guid}.parameterlog.${log._meta.guid}.menu.${menu.menu}.icon.${idx}`"
                  class="svg-icon w-25px text-center"
                >
                  <font-awesome-icon :icon="menuIcon && menuIcon.icon || ['fas', 'question']" />
                </i>

                <span>{{ $tu(`luxura_logs.parameter.modeltype_${log.model_type}.version_${log.version_id}.menu_${menu.menu}.title`) || `Menu ${menu.menu}` }}</span>

                <!-- begin::Menu - Mutation indicator -->
                <template v-if="canUpdateSettings() === true && getMenuMutations(menu.menu).length > 0">
                  <!-- begin::Menu - Mutation indicator - Invalid -->
                  <i
                    v-if="hasInvalidValuesInMenu(menu.menu) === true"
                    :class="['label label-dot label-lg label-danger ml-3']"
                  />
                  <!-- end::Menu - Mutation indicator - Invalid -->

                  <!-- begin::Menu - Mutation indicator - Valid -->
                  <i
                    v-else
                    :class="['label label-dot label-lg label-warning ml-3']"
                  />
                  <!-- begin::Menu - Mutation indicator - Valid -->
                </template>
                <!-- end::Menu - Mutation indicator -->
              </div>
            </div>
            <b-collapse :id="`parameter.menu.${menu.menu}`" accordion="parameter-accordion">
              <div
                v-if="menu.parameters && menu.parameters.length > 0"
                class="card-body px-3"
              >
                <parameter-row
                  v-for="(parameter, idx) in menu.parameters"
                  :key="`equipment.${equipment._meta.guid}.parameterlog.${log._meta.guid}.menu.${menu.menu}.submenu.${parameter.submenu}`"
                  :ref="`equipment.${equipment._meta.guid}.parameterlog.${log._meta.guid}.menu.${menu.menu}.submenu.${parameter.submenu}`"

                  :class="['row mt-3', { 'pb-3 border-bottom': idx !== (menu.parameters.length - 1) }]"

                  :action="(canModifySetting(parameter.menu, parameter.submenu) === true ? 'EDIT' : 'VIEW')"
                  :equipment="equipment"
                  :log="log"
                  :parameter="parameter"

                  :value="getParameterMutationValue(parameter.menu, parameter.submenu)"

                  @change="onParameterChange"
                />
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <!-- end::Content -->

    <!-- begin::Bottom row -->
    <div v-if="isMounted" class="row border-top mt-3 pt-3">
      <div class="col-12 mt-3 order-0 col-lg-auto mt-lg-0 my-lg-auto order-lg-0">
        <span v-if="log" class="text-muted">
          {{ $moment.utc(log.log_date).tz(equipment.location.timezone || 'UTC').format('HH:mm [on] DD MMMM YYYY') }} (#{{ log.log_id }} - {{ log.controller_serial }})
        </span>
      </div>

      <div v-if="canUpdateSettings() === true" class="col-12 mt-3 order-1 col-lg-auto mt-lg-0 ml-lg-auto order-lg-0">
        <button
          class="btn btn-success btn-block w-100 w-lg-auto"
          :disabled="isLoading || (mutations && mutations.length === 0)"
          @click.prevent="onSubmit"
        >
          <span>Submit</span>
        </button>
      </div>
    </div>
    <!-- end::Bottom row -->

    <!-- begin::Hidden input for import -->
    <input v-if="isMounted && canUpdateSettings() === true" ref="parametersFileImportInput" type="file" class="d-none" :disabled="isLoading" accept="text/plain" @change="onParametersFileImport($event.target.files)" />
    <!-- end::Hidden input for import -->

    <!-- begin::Modals -->
    <settings-history-modal
      ref="settingsHistoryModal"
      :equipment="equipment"
      :device="device"
    />

    <datetime-option-modal ref="datetimeOptionModal" />
    <parameter-instructions-modal ref="parameterInstructionsModal" />
    <settings-requests-modal ref="settingsRequestsModal" v-if="$auth.hasPermission('equipment_settings_requests.get')" />
    <!-- end::Modals -->
  </div>
</template>

<script>
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin, validations } from '@vedicium/core-vue';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';

import Equipment from '@/libs/classes/equipment';
import LuxuraLog from '@/libs/classes/luxura_log';
import LuxuraLogParameter, { ParameterTypes } from '@/libs/classes/luxura_log.parameter';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';
import parameterRow from './components/parameter.row.vue';
import actionsDropdown from './components/actions.dropdown.vue';
import settingsHistoryModal from './modals/settings-history.modal.vue';
import datetimeOptionModal from './modals/datetime-option.modal.vue';
import parameterInstructionsModal from './modals/parameter-instructions.modal.vue';
import settingsRequestsModal from './modals/settings-requests.modal.vue';

export default {
  mixins: [equipmentMixin, errorComponentMixin],
  components: {
    parameterRow,
    errorTranslationAlert,
    actionsDropdown,
    settingsHistoryModal,
    datetimeOptionModal,
    parameterInstructionsModal,
    settingsRequestsModal,
  },
  data () {
    return {
      isLoading: false,
      isMounted: false,

      log: null,
      mutations: [],

      settings: {
        special_mode: false,
      },
    };
  },
  computed: {
    logMenus () {
      const menus = this.log && this.log.getMenus();
      const isUpdatingSettings = this.canUpdateSettings();
      return menus && menus
        // Filter menu's first
        .filter((row) => row.menu !== 0)

        // Then, map the parameters of that menu
        .map((row) => ({
          ...row,
          parameters: row.parameters.filter((parameter) => {
            // Filter out types
            if ([ParameterTypes.MENU_NUMERIC_ULONG, ParameterTypes.MENU_TIME, ParameterTypes.MENU_DATE].includes(Number(parameter.type))) {
              return false;
            }

            // Filter out unvisible parameters (special mode has to be turned on)
            if (this.settings.special_mode !== true && parameter.visible === false) {
              return false;
            }

            // Filter out non-writeable parameters, because we're here to change settings
            if (parameter.writeable === false) {
              return false;
            }

            // Filter out 'hour reset'
            if (this.log.model_type === 'Jewel') {
              // Hide reset menu's if not updating settings
              if (isUpdatingSettings === false) {
                if (parameter.menu === 2 && [2, 4, 6, 8, 10, 12, 14, 16, 18, 20].includes(parameter.submenu)) {
                  return false;
                }
              }
            }

            // Filter out 'hour reset'
            if (this.log.model_type === 'LXP16') {
              // Hide reset menu's if not updating settings
              if (isUpdatingSettings === false) {
                if (parameter.menu === 2 && [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24].includes(parameter.submenu)) {
                  return false;
                }
              }
            }

            if (this.log.model_type === 'X5X7II') {
              // Hide reset menu's if not updating settings
              if (isUpdatingSettings === false) {
                if (parameter.menu === 2 && [2, 4, 8, 11, 13].includes(parameter.submenu)) {
                  return false;
                }
              }
            }

            if (this.log.model_type === 'V6V8') {
              // Hide reset menu's if not updating settings
              if (isUpdatingSettings === false) {
                if (parameter.menu === 2 && [2].includes(parameter.submenu)) {
                  return false;
                }
              }
            }

            return true;
          }),
        }));
    },
  },

  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  async mounted () {
    // Check if log query is set
    if (this.$route.query.log) {
      this.$eventhub.emit('modals:equipment:settings:history:open', {
        log: this.$route.query.log,
      });
    }

    this.$set(this, 'isLoading', true);
    try {
      this.$set(this, 'log', await this.getLastParameterLog());
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
    } finally {
      this.$set(this, 'isLoading', false);
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },
  beforeRouteLeave (_to, _from, next) {
    if (!this.mutations || this.mutations.length === 0) {
      next();
      return;
    }

    // Ask for confirmation from browser if you're sure that you want to leave because of mutations
    // eslint-disable-next-line no-alert
    if (window.confirm(this.$t('window.beforeunload'))) {
      next();
      return;
    }

    next(false);
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },

  methods: {
    canUpdateSettings () {
      return this.$auth.hasPermission('equipment.controller.parameters.update') && (this.device && this.device.isLegacyDevice() === false);
    },
    canModifySetting (menu, submenu) {
      // First, verify if settings can be modified
      if (this.canUpdateSettings() === false) {
        return false;
      }

      // Verify if user has permission for advanced mode
      // If so, always return true
      if (this.$auth.hasPermission('equipment.controller.parameters.update.advanced') === true) {
        return true;
      }

      // Define which parameters can be modified without 'advanced' permission
      const defaultParametersToModify = {
        menu_1: [7, 8, 16],
        menu_2: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
        menu_4: [1],
        menu_5: [1],
        menu_6: [3, 4],
        menu_8: [1, 8],
        menu_9: [3, 10],
        menu_11: [1, 2, 3, 4],
        menu_14: [3],
        menu_15: [1],
      };

      if (!defaultParametersToModify[`menu_${menu}`] || defaultParametersToModify[`menu_${menu}`].includes(submenu) === false) {
        return false;
      }

      return true;
    },
    beforeWindowUnload (e) {
      if (!this.mutations || this.mutations.length === 0) {
        return undefined; // Use undefined instead of 'null', because of IE implementation
      }

      const dialogText = this.$t('window.beforeunload');
      e.preventDefault();
      e.returnValue = dialogText;
      return dialogText;
    },

    onAction (action = null, payload = null) {
      switch (action) {
        case 'download': {
          this.onDownload(payload.log, payload.options);
          break;
        }

        case 'settings': {
          this.$set(this, 'settings', payload);
          break;
        }

        case 'export': {
          this.$set(this, 'isLoading', true);
          try {
            const parametersFile = LuxuraLogParameter.convertParametersToFile([].concat(this.log.parameters), {
              exclude_datetime: true,
              include_invisible: this.settings.special_mode,
            });

            // Take advantage of the HTTP.downloadFile method
            this.$http.downloadFile(`${this.equipment.serial}.${this.log.controller_serial}.parameter.${this.log.log_id}.txt`, parametersFile, 'text/plain');
          } catch (e) {
            this.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          } finally {
            this.$set(this, 'isLoading', false);
            this.$refs.actionsDropdown.close();
          }
          break;
        }

        case 'import': {
          // Should import
          if (this.$refs.parametersFileImportInput) {
            this.$refs.parametersFileImportInput.click();
          }

          this.$refs.actionsDropdown.close();
          break;
        }

        default: {
          console.warn(`Action '${action}' unknown`);
          this.$refs.actionsDropdown.close();
        }
      }
    },
    onHistoryButtonClick () {
      const vm = this;
      vm.$eventhub.emit('modals:equipment:settings:history:open');
    },
    onRequestsButtonClick () {
      const vm = this;
      vm.$eventhub.emit('modals:equipment:settings:requests:open', {
        equipment: this.equipment,
      });
    },

    async onDownload (log = null, options = {}) {
      if (!log || log instanceof LuxuraLog === false) {
        return;
      }

      this.$set(this, 'isLoading', true);
      try {
        await log.download(options);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }
    },
    async onParametersFileImport (files = []) {
      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Import settings',
        message: `Are you sure that you want to import the settings in <strong>${file.name}</strong> for sunbed ${vm.equipment.serial}?<br />This will override the current settings.`,
        variant: 'warning',
        buttonText: 'I\'m sure',
        async onSubmit () {
          try {
            // Create formData, which is needed for an upload
            const formData = new FormData();
            formData.append('file', file);

            // Upload file
            await vm.$http.post(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/parameters`, {
              query: {
                type: 'file',
                timeout: 10 * 60, // Timeout of 10 minutes
              },
              body: formData,
            });
          } catch (e) {
            vm.$errors.handle(e, { ui_element: true });
            console.error(e);
            return;
          }

          vm.$eventhub.emit('modals:equipment:settings:instructions:open');
          vm.$set(vm, 'mutations', []);
        },
      });
    },

    async validate () {
      const validationArray = this.mutations.flatMap((row) => this.$refs[`equipment.${this.equipment._meta.guid}.parameterlog.${this.log._meta.guid}.menu.${row.menu}.submenu.${row.submenu}`]).map((row) => row.$v).filter((row) => row !== undefined);
      await validations.validateArray(validationArray);
    },
    async onSubmit () {
      this.$errors.clear();
      this.$set(this, 'isLoading', true);
      try {
        await this.validate();
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      const mutations = [...this.mutations];
      const timezone = this.equipment.location.timezone || 'UTC';
      const vm = this;
      vm.$eventhub.emit('modals:equipment:settings:datetime-option:open', {
        title: 'Time & date',
        message: `Would you like to set your sunbed’s time and date to your local time and date?<br />${timezone} timezone will be used.`,
        async onSubmit (useDateTime) {
          // Add date & time when user pressed 'Yes'
          if (useDateTime === true) {
            // Determine date & time based on timezone of equipment
            const equipmentDateTime = moment.tz(timezone);

            // Determine time parameter and date parameter based on parameter type
            const timeParameter = vm.log.parameters.find((parameter) => parameter.type === ParameterTypes.MENU_TIME);
            const dateParameter = vm.log.parameters.find((parameter) => parameter.type === ParameterTypes.MENU_DATE);

            // Push changes to mutations
            mutations.push(
              (timeParameter ? timeParameter.clone().merge({ value: equipmentDateTime.format('HH:mm:ss') }) : null),
              (dateParameter ? dateParameter.clone().merge({ value: equipmentDateTime.format('YY-MM-DD') }) : null),
            );
          }

          // Post parameters to device
          try {
            await vm.$ws.post(`${Equipment.uri}/${vm.equipment._meta.guid}/controller/parameters`, {
              query: {
                timeout: 10 * 60, // Timeout of 10 minutes
              },
              body: LuxuraLogParameter.convertParametersToFile(mutations, {
                exclude_datetime: false,
                include_invisible: (vm.settings.special_mode === true),
              }),
            });
          } catch (e) {
            vm.$errors.handle(e, { ui_elemet: true });
            console.error(e);
            return;
          }

          vm.$eventhub.emit('modals:equipment:settings:instructions:open');
          vm.$set(vm, 'mutations', []);
        },
      });
    },

    getMenuIcon (menu = null) {
      return this.log && this.log.getMenuIcon(menu);
    },
    getMenuMutations (menu = null) {
      return (this.mutations || []).filter((row) => row.menu === menu, []);
    },
    getParameterMutation (menu = null, submenu = null) {
      return (this.mutations || []).find((row) => row.menu === menu && row.submenu === submenu) || null;
    },
    getParameterMutationValue (menu = null, submenu = null) {
      const mutation = this.getParameterMutation(menu, submenu);
      return (mutation ? mutation.value : undefined);
    },

    hasInvalidValuesInMenu (menu = null) {
      return (this.log.parameters || [])
        .filter((row) => row.menu === menu)
        .map((row) => {
          const ref = this.$refs[`equipment.${this.equipment._meta.guid}.parameterlog.${this.log._meta.guid}.menu.${row.menu}.submenu.${row.submenu}`];
          return (Array.isArray(ref) ? ref[0] : ref);
        })
        .filter((row) => !!row && row.$v)
        .some((row) => row.$v.$error);
    },

    async getLastParameterLog (options = {}) {
      const parameterLogs = await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs`, {
        ...(options || {}),
        query: {
          sort: 'log_date|desc',
          filter: {
            type: 'parameter',
            'log_date|lte': moment.utc().add(24, 'hours').valueOf(),
          },
          limit: 1,
        },
      });

      if (parameterLogs.length === 0) {
        return null;
      }

      return new LuxuraLogParameter().merge(parameterLogs[0]);
    },

    onParameterChange (menu = null, submenu = null, value = null) {
      if (this.canUpdateSettings() !== true) {
        return;
      }

      if (
        menu === undefined || menu === null
        || submenu === undefined || submenu == null
        || value === undefined || value === null
      ) {
        return;
      }

      // Check if parameter can be found
      const parameter = (this.log.parameters || []).find((row) => row.menu === menu && row.submenu === submenu);
      if (!parameter || parameter.writeable === false) {
        return;
      }

      // Copy mutations first and remove parameter from mutations, so we can just push if it needs to be added later on
      const mutations = [...this.mutations].filter((row) => (row.menu === menu && row.submenu === submenu) === false, []);

      // If value is other than current parameter value, add it to the mutations
      if (isEqual(parameter.value, value) === false) {
        mutations.push(parameter.clone().merge({ value }));
      }

      this.$set(this, 'mutations', mutations);
    },
  },
};
</script>
