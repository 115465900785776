<template>
  <div v-if="parameter && log" class="row" :parameter-menu="parameter.menu" :parameter-submenu="parameter.submenu">
    <div class="col-12 col-md-9 my-auto">
      <span class="overflow-ellipsis font-weight-bold">
        {{ $tu(`luxura_logs.parameter.modeltype_${log.model_type}.version_${log.version_id}.menu_${parameter.menu}.submenu_${parameter.submenu}.title`) || `${parameter.name}` }}
      </span>
      <span v-if="$te(`luxura_logs.parameter.modeltype_${log.model_type}.version_${log.version_id}.menu_${parameter.menu}.submenu_${parameter.submenu}.description`)">
        {{ $t(`luxura_logs.parameter.modeltype_${log.model_type}.version_${log.version_id}.menu_${parameter.menu}.submenu_${parameter.submenu}.description`) }}
      </span>

      <!-- begin::Label - Mutation -->
      <i
        v-if="isReadOnly === false"
        :class="['label label-dot ml-3', ((value !== undefined && value !== parameter.value) ? 'label-warning' : 'label-info')]"
      />
      <!-- end::Label - Mutation -->
    </div>

    <div class="col mt-3 my-md-auto text-right">
      <template v-if="parameterValueComponent">

        <!-- begin::ambient-ambient-effect-->
        <template v-if="parameterValueComponent.type === 'custom:ambientflowlight-effect'">
          <div class="input-group">
            <select
              class="form-control"

              :value="typeof value === 'number' ? value : parameter.value"
              :readonly="isReadOnly"

              @change="onParameterChange(Number($event.target.value));"
            >
              <option
                v-for="option in parameterValueComponent.options"
                :key="`menu_${parameter.menu}.submenu_${parameter.submenu}.value_${option.value}`"
                :value="option.value"
                v-html="option.text || option.value"
              />
            </select>
            <div class="input-group-append">
              <div class="input-group-text cursor-pointer" @click.prevent="onEffectOption">
                <i class="svg-icon text-info">
                  <font-awesome-icon :icon="['fas', 'info-circle']" />
                </i>
              </div>
            </div>
          </div>
          <effect-option-modal ref="effectOptionModal"/>
        </template>
        <!-- end::ambient-ambient-effect-->

        <!-- begin::custom-ambient-color -->
        <template v-if="parameterValueComponent.type === 'custom:ambientflowlight-color'">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
                :style="{
                  backgroundColor: $t(`luxura_logs.parameter.modeltype_${log.model_type}.version_${log.version_id}.menu_${parameter.menu}.submenu_${parameter.submenu}.values[${value !== undefined ? value : parameter.value}].color`)
                }">
              </span>
            </div>
            <select
              class="form-control"

              :value="(value !== undefined ? value : parameter.value)"
              :readonly="isReadOnly"

              @change="onParameterChange(Number($event.target.value))"
            >
              <option
                v-for="option in parameterValueComponent.options"
                :key="`menu_${parameter.menu}.submenu_${parameter.submenu}.value_${option.value}`"
                :value="option.value"
                v-html="option.text || option.value"
              />
            </select>
          </div>
        </template>
        <!-- end::custom-ambient-color -->

        <!-- begin::Dropdown -->
        <template v-if="parameterValueComponent.type === 'dropdown'">
          <select
            class="form-control"

            :value="(value !== undefined ? value : parameter.value)"
            :readonly="isReadOnly"

            @change="onParameterChange(Number($event.target.value))"
          >
            <option
              v-for="option in parameterValueComponent.options"
              :key="`menu_${parameter.menu}.submenu_${parameter.submenu}.value_${option.value}`"
              :value="option.value"
              v-html="option.text || option.value"
            />
          </select>
        </template>
        <!-- end::Dropdown -->

        <!-- begin::Slider -->
        <template v-if="parameterValueComponent.type === 'slider'">
          <div class="input-group">
            <b-form-input
              type="range"
              :min="parameterValueComponent.range[0]"
              :max="parameterValueComponent.range[1]"
              :step="parameterValueComponent.step"
              :value="value || parameter.value"

              :readonly="isReadOnly"

              @change="onParameterChange($event, { number: true })"
            />
            <div class="input-group-append">
              <span class="input-group-text min-w-50px justify-content-center">
                {{ value || parameter.value }}
              </span>
            </div>
          </div>
        </template>
        <!-- end::Slider -->

        <!-- begin::Switch -->
        <template v-if="parameterValueComponent.type === 'switch'">
          <span :class="['switch', { 'switch-success': (value !== undefined ? value === 1 : parameter.value === 1) }]">
            <label class="ml-auto">
              <input
                type="checkbox"
                :checked="value === 1 || parameter.value === 1"

                :disabled="isReadOnly"
                :readonly="isReadOnly"

                @change="onParameterChange($event.target.checked === true ? 1 : 0)"
              >
              <span />
            </label>
          </span>
        </template>
        <!-- end::Switch -->

        <!-- begin::Input -->
        <template v-if="parameterValueComponent.type === 'input'">
          <input
            type="text"
            :class="['form-control', { 'is-invalid': (value && $v.value.$error) }]"

            :value="value || parameterValueComponent.value || parameter.value"
            :placeholder="parameterValueComponent.placeholder"

            :readonly="isReadOnly"

            v-inputmask="maskedInput"

            @change="onParameterChange($event.target.value)"
          />
        </template>
        <!-- end::Input -->
      </template>
    </div>
  </div>
</template>

<script>
import format from '@/libs/format';
import moment from 'moment-timezone';
import { pincode, hhmm, minuteSeconds } from '@/libs/validators';
import { inputmaskDirective } from '@vedicium/metronic-vue';

import Equipment from '@/libs/classes/equipment';
import { AMBIENT_COLORS, AMBIENT_EFFECTS } from '@/libs/constants';
import LuxuraLogParameter, { ParameterTypes, ParameterUnits, Parameter } from '@/libs/classes/luxura_log.parameter';
import effectOptionModal from '../modals/effect-option.modal.vue';

export default {
  components: {
    effectOptionModal,
  },
  props: {
    action: {
      type: String,
      default: 'VIEW',
    },

    equipment: {
      type: Equipment,
      default: null,
    },
    log: {
      type: LuxuraLogParameter,
      default: null,
    },
    parameter: {
      type: Parameter,
      default: null,
    },

    value: {
      type: [String, Number],
      default: undefined,
    },
  },
  directives: {
    inputmask: inputmaskDirective,
  },
  computed: {
    maskedInput () {
      if (this.parameter) {
        if (this.parameter.type === ParameterTypes.MENU_PINCODE) {
          return '####';
        }

        if (this.parameter.type === ParameterTypes.MENU_IDCODE) {
          return '##';
        }

        if (this.parameter.type === ParameterTypes.MENU_TIME_HOUR_MIN) {
          return { alias: 'datetime', inputFormat: 'HH:MM' };
        }

        if (this.parameter.type === ParameterTypes.MENU_TIME_MIN_SEC) {
          return { alias: 'datetime', inputFormat: 'MM:ss' };
        }
      }

      return null;
    },

    isReadOnly () {
      return (!this.action || this.action === 'VIEW' || this.parameter.writeable === false);
    },

    parameterValueComponent () {
      const parameter = { ...this.parameter };

      // Slider
      if (parameter.unit === ParameterUnits.UNIT_PERCENT) {
        // Slider for NUMERIC
        if (parameter.type === ParameterTypes.MENU_NUMERIC) {
          return { type: 'slider', range: [Number(parameter.min), Number(parameter.max)], step: Number(parameter.step) };
        }
      }

      // Most of the dropdowns
      if ([
        ParameterTypes.MENU_NUMERIC,
        ParameterTypes.MENU_NUMERIC_STEP100,
        ParameterTypes.MENU_UNIT_TYPE,
        ParameterTypes.MENU_LANGUAGE,
        ParameterTypes.MENU_REGELING,
        ParameterTypes.MENU_AUDIOCHANNEL,
        ParameterTypes.MENU_FREQUENCY,
        ParameterTypes.MENU_NUMERIC_SIGNED,
        ParameterTypes.MENU_LAMP,
        ParameterTypes.MENU_LAMP_SPECIAL,
        ParameterTypes.MENU_FILTER,
        ParameterTypes.MENU_BOOSTER,
        ParameterTypes.MENU_NUMERIC_OFF,
      ].includes(parameter.type)) {
        // Default
        const component = { type: 'dropdown', options: [] };

        // Determine if dropdown is custom
        switch (true) {
          case (this.isAmbientFlowlightEffectMenu() === true): {
            component.type = 'custom:ambientflowlight-effect';
            break;
          }

          case (this.isAmbientFlowlightColorsMenu() === true): {
            component.type = 'custom:ambientflowlight-color';
            break;
          }

          default: {
            break;
          }
        }

        // Add 'off' for 'MENU_NUMERIC_OFF'
        if (ParameterTypes.MENU_NUMERIC_OFF) {
          component.options.push({ value: 255, text: 'Off' });
        }

        for (let i = Number(parameter.min); i <= Number(parameter.max); i += Number(parameter.step)) {
          const option = { value: i, text: i };

          if (parameter.unit === ParameterUnits.UNIT_NONE) {
            switch (true) {
              // Check if translation is found
              case this.$te(`luxura_logs.parameter.modeltype_${this.log.model_type}.version_${this.log.version_id}.menu_types.type_${parameter.type}.value_${i}`): {
                option.text = this.$t(`luxura_logs.parameter.modeltype_${this.log.model_type}.version_${this.log.version_id}.menu_types.type_${parameter.type}.value_${i}`);
                break;
              }

              // Check if unit is found in unit list
              case !!(this.log.json_data.unit[(parameter.type).toString()] && this.log.json_data.unit[(parameter.type).toString()][(i).toString()]): {
                option.text = this.log.json_data.unit[(parameter.type).toString()][(i).toString()];
                break;
              }

              default:
                option.text = i;
            }
          }

          if (parameter.unit === ParameterUnits.UNIT_SECONDS) {
            option.text = `${i} ${this.$tc('plural.seconds', i)}`;
          }

          if (parameter.unit === ParameterUnits.UNIT_MINUTES) {
            option.text = `${i} ${this.$tc('plural.minutes', i)}`;
          }

          if (parameter.unit === ParameterUnits.UNIT_HOURS) {
            option.text = `${i} ${this.$tc('plural.hours', i)}`;
          }

          if (parameter.unit === ParameterUnits.UNIT_100HOUR) {
            option.text = `${i * 100} ${this.$tc('plural.hours', (i * 100))}`;
          }

          if (parameter.unit === ParameterUnits.UNIT_PERCENT) {
            option.text = `${i}%`;
          }

          if (parameter.unit === ParameterUnits.UNIT_DEGREE) {
            // Convert to Fahrenheit
            if (['United States', 'Belize', 'Palau', 'The Bahamas', 'Cayman Islands', 'Liberia'].includes(this.equipment.location && this.equipment.location.country)) {
              option.text = `${format.celsiusToFahrenheit(i)} &deg;F`;
            } else {
              option.text = `${i} &deg;C`;
            }
          }

          if (this.isAmbientFlowlightEffectMenu() === true) {
            const menuEffectName = this.$t(`luxura_logs.parameter.modeltype_${this.log.model_type}.version_${this.log.version_id}.menu_${parameter.menu}.submenu_${parameter.submenu}.values[${i}].name`);
            option.text = `${menuEffectName} (${i})`;
          }

          if (this.isAmbientFlowlightSpeedMenu() === true || this.isLEDBarsFadeSpeed()) {
            // Set everything to 'seconds' only 1 second is 'second'
            option.text = `${i / 10} ${this.$tc('plural.seconds', i / 10 === 1 ? 1 : 2)}`;
          }

          if (this.isAmbientFlowlightColorsMenu() === true) {
            const menuColors = this.$t(`luxura_logs.parameter.modeltype_${this.log.model_type}.version_${this.log.version_id}.menu_${parameter.menu}.submenu_${parameter.submenu}.values[${i}].name`);
            option.text = `${menuColors} (${i})`;
          }

          component.options.push(option);
        }

        return component;
      }

      // Switch
      if ([
        ParameterTypes.MENU_NO_YES,
        ParameterTypes.MENU_OFF_ON,
        ParameterTypes.MENU_DEGREE,
      ].includes(parameter.type)) {
        return { type: 'switch' };
      }

      // Input
      if ([
        ParameterTypes.MENU_PINCODE,
        ParameterTypes.MENU_IDCODE,
        ParameterTypes.MENU_TIME_HOUR_MIN,
        ParameterTypes.MENU_TIME_MIN_SEC,
      ].includes(parameter.type)) {
        const component = { type: 'input' };

        switch (parameter.type) {
          case ParameterTypes.MENU_PINCODE:
            component.placeholder = 'XXXX';
            break;

          case ParameterTypes.MENU_IDCODE:
            component.placeholder = 'XX';
            break;

          case ParameterTypes.MENU_TIME_HOUR_MIN:
            component.placeholder = 'HH:mm';
            component.value = moment(parameter.value, 'H:m').format('HH:mm');
            break;

          case ParameterTypes.MENU_TIME_MIN_SEC:
            component.placeholder = 'mm:ss';
            component.value = moment(parameter.value, 'm:s').format('mm:ss');
            break;

          default:
            component.placeholder = null;
        }

        return component;
      }

      return null;
    },
  },

  validations: {
    value: {
      // eslint-disable-next-line
      valid: function (value) {
        if ([ParameterTypes.MENU_PINCODE].includes(this.parameter.type)) {
          return pincode(value);
        }

        if ([ParameterTypes.MENU_TIME_HOUR_MIN].includes(this.parameter.type)) {
          return hhmm(value);
        }

        if ([ParameterTypes.MENU_TIME_MIN_SEC].includes(this.parameter.type)) {
          return minuteSeconds(value);
        }

        return true;
      },
    },
  },

  methods: {
    onEffectOption () {
      const vm = this;

      vm.$eventhub.emit('modals:equipment:settings:effect-option:open', {
        effects: this.parameterValueComponent.options,
        effectValue: this.value !== null && typeof this.value === 'number' ? this.value : this.parameter.value,
        effectConfig: AMBIENT_EFFECTS,
        effectColor: AMBIENT_COLORS,
        onSubmit: (value) => {
          vm.onParameterChange(Number(value));
        },
      });
    },
    onParameterChange (value = null, options = {}) {
      if (options) {
        if (options.number === true) {
          value = Number(value); // eslint-disable-line no-param-reassign
        }
      }

      this.$v.value.$touch();
      this.$emit('change', this.parameter.menu, this.parameter.submenu, value);
    },

    isAmbientFlowlightEffectMenu () {
      // Luxura V6/V8 is a different menu
      if (this.log.model_type === 'V6V8') {
        return this.parameter.menu === 8 && this.parameter.submenu === 1;
      }

      return this.parameter.menu === 11 && this.parameter.submenu === 1;
    },
    isAmbientFlowlightSpeedMenu () {
      // Luxura V6/V8 is a different menu
      if (this.log.model_type === 'V6V8') {
        return this.parameter.menu === 8 && this.parameter.submenu === 2;
      }

      return this.parameter.menu === 11 && this.parameter.submenu === 2;
    },
    isLEDBarsFadeSpeed () {
      if (this.log.model_type === 'Jewel') {
        return this.parameter.menu === 11 && this.parameter.submenu === 6;
      }

      return false;
    },
    isAmbientFlowlightColorsMenu () {
      // Luxura V6/V8 is a different menu
      if (this.log.model_type === 'V6V8') {
        return this.parameter.menu === 8 && this.parameter.submenu === 4;
      }

      return this.parameter.menu === 11 && this.parameter.submenu === 4;
    },
  },
};
</script>
